import { IconProps } from "@app/types"

const StatusDelivery = ({ width = 24, height = 24, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path
        d="M20.97 6.20156L12.72 1.6875C12.4996 1.5657 12.2518 1.50182 12 1.50182C11.7482 1.50182 11.5004 1.5657 11.28 1.6875L3.03 6.20344C2.7944 6.33235 2.59772 6.52215 2.46052 6.75303C2.32331 6.9839 2.25061 7.24737 2.25 7.51594V16.4822C2.25061 16.7508 2.32331 17.0142 2.46052 17.2451C2.59772 17.476 2.7944 17.6658 3.03 17.7947L11.28 22.3106C11.5004 22.4324 11.7482 22.4963 12 22.4963C12.2518 22.4963 12.4996 22.4324 12.72 22.3106L20.97 17.7947C21.2056 17.6658 21.4023 17.476 21.5395 17.2451C21.6767 17.0142 21.7494 16.7508 21.75 16.4822V7.51688C21.7499 7.24783 21.6774 6.98377 21.5402 6.75236C21.403 6.52095 21.206 6.3307 20.97 6.20156ZM12 3L19.5319 7.125L16.7409 8.65313L9.20813 4.52813L12 3ZM12 11.25L4.46812 7.125L7.64625 5.385L15.1781 9.51L12 11.25ZM3.75 8.4375L11.25 12.5419V20.5847L3.75 16.4831V8.4375ZM20.25 16.4794L12.75 20.5847V12.5456L15.75 10.9041V14.25C15.75 14.4489 15.829 14.6397 15.9697 14.7803C16.1103 14.921 16.3011 15 16.5 15C16.6989 15 16.8897 14.921 17.0303 14.7803C17.171 14.6397 17.25 14.4489 17.25 14.25V10.0828L20.25 8.4375V16.4784V16.4794Z"
        fill={fill}
      />
    </svg>
  )
}

export { StatusDelivery }
