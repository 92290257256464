import React from "react"
import PropTypes from "prop-types"

function Question({ width, height, className, fill = "#000", pathFill = "#555", ...rest }) {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" className={className} {...rest}>
      <g fill="none" fillRule="evenodd" opacity={0.6}>
        <circle fill={fill} fillRule="nonzero" cx={10.5} cy={10.5} r={10.5} />
        <path
          d="M10.946 11.992v-.34a1.642 1.642 0 01.24-.865c.157-.262.355-.498.585-.7.23-.2.458-.406.683-.618a2.8 2.8 0 00.578-.8c.164-.336.247-.705.241-1.079a2.1 2.1 0 00-.741-1.651 2.719 2.719 0 00-1.859-.65c-.395 0-.787.071-1.157.208a5.9 5.9 0 00-1.327.823l.494.65a3.731 3.731 0 011.951-.836 1.62 1.62 0 011.222.429c.312.266.488.657.481 1.066.002.304-.079.603-.234.864-.153.26-.344.496-.566.7-.221.204-.442.41-.663.618a2.774 2.774 0 00-.565.773c-.159.321-.24.676-.234 1.034v.366h.871v.008zm.065 3.017v-1h-1v1h1z"
          fill={pathFill}
        />
      </g>
    </svg>
  )
}

Question.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  className: PropTypes.string,
}

export { Question }
