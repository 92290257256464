import PropTypes from "prop-types"

function Trash({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 16 18" {...rest}>
      <path
        d="M1 4.2h14m-1.556 0v11.2c0 .884-.696 1.6-1.555 1.6H4.11c-.859 0-1.555-.716-1.555-1.6V4.2m2.333 0V2.6c0-.884.696-1.6 1.555-1.6h3.112c.859 0 1.555.716 1.555 1.6v1.6"
        fill={fill}
        stroke={stroke}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  )
}

Trash.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { Trash }
