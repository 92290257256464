import { IconProps } from "@app/types"

const PictureUpload = ({
  width = 34,
  height = 32,
  fill = "#AFAFAF",
  stroke = "#AFAFAF",
  className,
  ...props
}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 32" fill="none" {...props}>
      <path
        d="M17.1223 17.9187L17.1002 17.9499L17.0297 18.0313L16.9556 18.096L16.9257 18.1177C16.9086 18.1294 16.8918 18.14 16.8745 18.1499L16.7823 18.1949L16.656 18.2336L16.5482 18.2486L16.4468 18.2483L16.3501 18.2351L16.2811 18.2175L16.215 18.1938L16.1358 18.1556L16.085 18.1248L16.0094 18.0674L15.936 17.9946L15.8655 17.9002L15.8238 17.8248L15.8089 17.7915L15.7738 17.6873L15.5982 17.0091C14.2884 12.1259 12.4812 9.25 9.5 9.25C6.47068 9.25 3.84217 14.1686 1.92155 24.1522L1.74987 25.069L1.75 25.25H19C19.3797 25.25 19.6935 25.5322 19.7432 25.8982L19.75 26C19.75 26.4142 19.4142 26.75 19 26.75H1C0.585786 26.75 0.25 26.4142 0.25 26V1C0.25 0.585786 0.585786 0.25 1 0.25H29C29.4142 0.25 29.75 0.585786 29.75 1V17C29.75 17.4142 29.4142 17.75 29 17.75C28.5858 17.75 28.25 17.4142 28.25 17V1.75H1.75L1.75032 18.2177C3.68437 11.2648 6.21391 7.75 9.5 7.75C13.251 7.75 15.3307 10.7203 16.7971 15.7252C17.8861 14.4099 19.1687 13.75 20.5 13.75C21.7815 13.75 23.6205 15.064 24.4208 16.6646C24.6061 17.0351 24.4559 17.4856 24.0854 17.6708C23.7149 17.8561 23.2644 17.7059 23.0792 17.3354C22.5178 16.2126 21.1706 15.25 20.5 15.25C19.3961 15.25 18.2671 15.9856 17.2936 17.624L17.1223 17.9187ZM22.5 6C23.3284 6 24 6.67157 24 7.5C24 8.2797 23.4051 8.92045 22.6445 8.99313L22.5 9C21.6716 9 21 8.32843 21 7.5C21 6.67157 21.6716 6 22.5 6Z"
        fill={fill}
      />
      <path
        d="M26 16C30.4183 16 34 19.5817 34 24C34 28.4183 30.4183 32 26 32C21.5817 32 18 28.4183 18 24C18 19.5817 21.5817 16 26 16ZM26 17.5C22.4101 17.5 19.5 20.4101 19.5 24C19.5 27.5899 22.4101 30.5 26 30.5C29.5899 30.5 32.5 27.5899 32.5 24C32.5 20.4101 29.5899 17.5 26 17.5Z"
        fill={fill}
      />
      <path
        d="M26 21.5V26.75M28.5 24H23.25"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { PictureUpload }
