import { IconProps } from "@app/types"

interface BookIconProps extends IconProps {
  style?: any
}

const BookLight = (props: BookIconProps) => {
  const { fill, stroke, width, height } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 60.5 46.0627002" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1.75-1.688h64v50h-64z" />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3.75 36.813h18.5c4.31 0 8 2.185 8 6.5M56.75 36.813h-18.5c-4.056 0-7.62 2.04-8 6"
          opacity={0.6}
        />
        <path
          stroke={stroke}
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M30.25 39.313v-31c0-4.315 3.69-7.5 8-7.5L59.75.75v39.063h-21.5M19.25 39.813H.75V.75l21.5.063c4.31 0 8 3.185 8 7.5v31"
        />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M2.25 39.813h20c3.624 0 7.068 2.221 8 5.5M58.25 39.813h-20c-3.545 0-6.995 2.272-8 5.5M30.25 8.469v36.344"
        />
        <g stroke={stroke} strokeWidth={1.5}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.396 26.781h14.049" />
          <path d="M8.396 11.156v15.625M22.445 11.156v15.625" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.396 11.156h14.049" />
        </g>
        <path
          fill={fill}
          stroke="transparent"
          d="m44.216 22.449-1.478 3.207-1.479-3.207-3.204-1.48 3.204-1.48 1.479-3.208 1.478 3.208 3.205 1.48zM46.311 10.483l-1.232 2.673-1.232-2.673-2.67-1.233 2.67-1.233 1.232-2.673 1.232 2.673 2.67 1.233zM54.65 15.705l-.985 2.139-.986-2.139-2.136-.986 2.136-.987.986-2.138.985 2.138 2.137.987z"
        />
      </g>
    </svg>
  )
}

export { BookLight }
