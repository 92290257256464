import { IconProps } from "@app/types"

const Viber = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
      <g fillRule="nonzero" fill="none">
        <circle cx={16} cy={16} r={15.25} stroke={stroke} strokeWidth={1.5} />
        <path
          fill={fill}
          d="M21.835 10.164c-.408-.358-2.06-1.495-5.738-1.512 0 0-4.339-.248-6.453 1.594-1.177 1.118-1.59 2.753-1.635 4.782-.044 2.03-.1 5.83 3.761 6.86h.004v1.573a.789.789 0 00.416.766c.534.158.845-.325 1.358-.845.28-.286.665-.707.957-1.028 1.641.144 3.294.029 4.9-.343a4.713 4.713 0 004.036-4.331c.502-3.921-.25-6.399-1.606-7.516zm.447 7.231a3.742 3.742 0 01-3.31 3.51c-1.38.32-2.8.435-4.215.34 0 0-1.67 1.914-2.193 2.412a.283.283 0 01-.24.094.23.23 0 01-.113-.269l.014-2.612c-3.264-.862-3.074-4.095-3.037-5.79.037-1.697.372-3.084 1.367-4.017 1.789-1.538 5.475-1.308 5.475-1.308 3.111.013 4.602.904 4.949 1.202 1.146.932 1.732 3.166 1.304 6.438zm-5.873.19a.588.588 0 00.45-.16l.306-.367a.7.7 0 01.856-.113c.525.28 1.018.616 1.473 1a.54.54 0 01.125.73v.005a3.047 3.047 0 01-.639.747l-.005.005a1.096 1.096 0 01-1.02.26l-.01-.004a12.59 12.59 0 01-3.282-1.724 9.892 9.892 0 01-3.177-4.41l-.01-.007a.967.967 0 01.277-.968l.005-.006c.229-.243.494-.448.787-.608h.005a.607.607 0 01.768.118 8.1 8.1 0 011.057 1.4c.171.261.12.61-.12.812l-.385.291a.521.521 0 00-.17.427 3.894 3.894 0 002.708 2.573h.001zm3.364-1.703a.206.206 0 00.207-.199 4.091 4.091 0 00-1.246-3.1 4.38 4.38 0 00-3.047-1.147.197.197 0 100 .395 3.972 3.972 0 012.76 1.036 3.721 3.721 0 011.12 2.82c.003.11.096.198.206.195zm-1.095-.407h-.005a.197.197 0 01-.143-.059.2.2 0 01-.06-.143 2.21 2.21 0 00-.62-1.671 2.65 2.65 0 00-1.813-.747.2.2 0 01-.195-.211.205.205 0 01.222-.183c.782.017 1.529.33 2.09.875a2.61 2.61 0 01.729 1.95.202.202 0 01-.205.189zm-1.067-.34a.203.203 0 01-.207-.188.967.967 0 00-1.048-1.032.196.196 0 11.023-.39 1.364 1.364 0 011.44 1.408.202.202 0 01-.196.207h-.012v-.005z"
        />
      </g>
    </svg>
  )
}

export { Viber }
