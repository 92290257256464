import { IconProps } from "@app/types"

const Chargeback = ({ width = 26, height = 26, fill = "none", stroke = "#000", className, ...rest }: IconProps) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 26 26" {...rest}>
      <g fill="none" fillRule="evenodd" transform="matrix(-1 0 0 1 26 0)">
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M14.978 22.588C14.03 22.856 13.031 23 12 23 5.95 23 1 18.05 1 12S5.95 1 12 1c4.29 0 7.975 2.475 9.79 5.995"
        />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M22.543 2.378 21.9 7.16l-4.909-.66"
        />
        <ellipse
          cx={20.091}
          cy={13}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          rx={4.909}
          ry={2.182}
        />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M15.182 13v3.273c0 1.205 2.197 2.182 4.909 2.182 2.711 0 4.909-.977 4.909-2.182V13"
        />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M15.182 16.273v3.272c0 1.205 2.197 2.182 4.909 2.182 2.711 0 4.909-.977 4.909-2.182v-3.272"
        />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M15.182 19.545v3.273c0 1.205 2.197 2.182 4.909 2.182C22.802 25 25 24.023 25 22.818v-3.273"
        />
        <path d="M0 0h26v26H0z" />
      </g>
    </svg>
  )
}

export { Chargeback }
