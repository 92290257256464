import PropTypes from "prop-types"

function EyeOn({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 20 15" {...rest}>
      <g fill={fill} fillRule="evenodd" transform="translate(1 1)">
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.286}
          d="M0 6.5S3.273 0 9 0s9 6.5 9 6.5S14.727 13 9 13C3.579 13 .357 7.176.028 6.554L0 6.5z"
        />
        <rect width={5.143} height={5.2} x={6.429} y={3.9} fill="#FFF" rx={2.571} />
      </g>
    </svg>
  )
}

EyeOn.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { EyeOn }
