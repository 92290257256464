import { IconProps } from "@app/types"

const Lock = ({ width, height, fill = "none", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 12 13" {...props}>
      <g
        fill={fill}
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        opacity={0.9}
        transform="translate(1 1)"
      >
        <rect width={10} height={7} y={4} rx={0.889} />
        <path d="M2 4V2.222C2 .995 3.343 0 5 0s3 .995 3 2.222V4" />
      </g>
    </svg>
  )
}

export { Lock }
