import { IconProps } from "@app/types"

const Cart = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <g fill={fill} fillRule="nonzero">
        <path d="M3.5.25a.75.75 0 01.675.423l.04.1.787 2.477H19a.75.75 0 01.732.915l-.03.098-3 8a.75.75 0 01-.6.48L16 12.75H7a.75.75 0 01-.675-.423l-.04-.1L2.951 1.75H1a.75.75 0 01-.743-.648L.25 1A.75.75 0 01.898.257L1 .25h2.5zm14.417 4.5H5.48l2.068 6.5h7.931l2.438-6.5zM7.5 15a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM15.5 15a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
      </g>
    </svg>
  )
}

export { Cart }
