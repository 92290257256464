import { IconProps } from "@app/types"

const GoogleDrive = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 18 15" {...props}>
      <g fillRule="nonzero" fill="none">
        <path fill="#4CAF50" d="M9.049 4.736L6.167 0 0 10.262 2.833 15z" />
        <path fill="#FFC107" d="M6.167 0l6.231 10.262H18L11.834 0z" />
        <path fill="#2196F3" d="M5.702 10.262L2.833 15h12.333L18 10.262z" />
      </g>
    </svg>
  )
}

export { GoogleDrive }
