import { IconProps } from "@app/types"

const NotFoundPhotoIconMobile = ({
  width = 30,
  height = 25,
  stroke = "#AFAFAF",
  fill = "#AFAFAF",
  className,
  ...props
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M26 .25a.75.75 0 0 1 .75.75v11.089a6.97 6.97 0 0 0-1.5-.72V1.75H4.75v8.001C6.066 5.972 7.73 4.05 9.857 4.05c2.724 0 4.242 2.07 5.309 5.576.76-.804 1.636-1.212 2.548-1.212 1.274 0 2.38.956 3.384 2.848-.492.138-.96.328-1.4.565-.698-1.281-1.374-1.913-1.984-1.913-.695 0-1.424.478-2.077 1.577l-.152.264-.05.07-.064.067-.079.065-.053.034-.097.048-.059.022-.063.017-.108.016-.083.001-.06-.004-.064-.011-.087-.024-.12-.052-.05-.03-.069-.05-.051-.047-.064-.072-.062-.093-.032-.065-.036-.103-.005-.02c-.921-3.75-2.195-5.974-4.272-5.974-1.84 0-3.553 2.958-4.87 9.029l-.185.874-.052.151v3.646H16.11c.095.523.247 1.026.45 1.5H4a.75.75 0 0 1-.75-.75V1A.75.75 0 0 1 4 .25h22ZM21.893 4c.65 0 1.178.537 1.178 1.2 0 .663-.527 1.2-1.178 1.2a1.19 1.19 0 0 1-1.179-1.2c0-.663.528-1.2 1.179-1.2Z"
      />
      <path
        fill={fill}
        fillRule="nonzero"
        d="M23 11a7 7 0 1 1 0 14 7 7 0 0 1 0-14Zm0 1.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M23 15.5v5.25M25.5 18h-5.25"
      />
      <path d="M0 0h30v25H0z" />
    </g>
  </svg>
)
export { NotFoundPhotoIconMobile }
