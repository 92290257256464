import PropTypes from "prop-types"

function ArrowDown({ width, height, fill = "none", stroke, className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 14 8" {...rest}>
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 1l6 6 6-6"
      />
    </svg>
  )
}

ArrowDown.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { ArrowDown }
