import { IconProps } from "@app/types"

const Insta = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M13 .5C6.096.5.5 6.096.5 13S6.096 25.5 13 25.5 25.5 19.904 25.5 13 19.904.5 13 .5zM13 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2z" />
        <path d="M15 7a4 4 0 014 4v4a4 4 0 01-4 4h-4a4 4 0 01-4-4v-4a4 4 0 014-4h4zm-2 2.8A3.216 3.216 0 009.8 13c0 1.755 1.445 3.2 3.2 3.2 1.755 0 3.2-1.445 3.2-3.2 0-1.755-1.445-3.2-3.2-3.2zm0 1.2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm3.2-1.9c-.4 0-.7.3-.7.7 0 .4.3.7.7.7.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z" />
      </g>
    </svg>
  )
}

export { Insta }
