import { IconProps } from "@app/types"

function VideoStatus({
  width = 80,
  height = 80,
  fill = "#AFAFAF",
  stroke = "#DEDEDE",
  radius = 35.25,
  className,
  ...props
}: IconProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40" cy="40" r={radius} stroke={stroke} strokeWidth="1.5" />
      <path
        d="M34 46.601V33.399C34 31.8741 35.6382 30.9101 36.9713 31.6507L48.853 38.2517C50.2247 39.0137 50.2247 40.9863 48.853 41.7483L36.9713 48.3493C35.6382 49.0899 34 48.1259 34 46.601Z"
        fill={fill}
      />
    </svg>
  )
}

export { VideoStatus }
