import { IconProps } from "@app/types"

interface SuccessProps extends IconProps {
  circleStroke?: string
  iconStroke?: string
}

const Success = ({
  width,
  height,
  fill,
  circleStroke = "#DEDEDE",
  iconStroke = "#AFAFAF",
  className,
  ...props
}: SuccessProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      {...props}
    >
      <g transform="translate(1 1)" strokeWidth={1.5} fill="none" fillRule="evenodd">
        <circle cx={20} cy={20} r={20} stroke={circleStroke} />
        <path stroke={iconStroke} strokeLinecap="round" strokeLinejoin="round" d="M12 20l6 6 11-11" />
      </g>
    </svg>
  )
}

export { Success }
