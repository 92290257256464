import { IconProps } from "@app/types"

interface StarIconProps extends IconProps {
  style?: any
}

const Star = (props: StarIconProps) => {
  const { fill, stroke } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7 1 1.854 3.756L13 5.362l-3 2.922.708 4.128L7 10.462l-3.708 1.95L4 8.284 1 5.362l4.146-.606z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export { Star }
