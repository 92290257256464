import { IconProps } from "@app/types"

interface BookIconProps extends IconProps {
  style?: any
}

const BookGenerate = (props: BookIconProps) => {
  const { fill, stroke, width, height } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 42 32" {...props}>
      <path stroke={stroke} fill="none" strokeLinejoin="round" strokeWidth={1.5} d="M16.5 2H5a1 1 0 0 0-1 1v15" />
      <path
        stroke={stroke}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M18 25H5a1 1 0 0 1-1-1v-3"
      />
      <path
        fill={fill}
        d="m14 15 .632 1.369L16 17l-1.368.631L14 19l-.632-1.369L12 17l1.368-.631L14 15ZM18 8l.631 1.369L20 10l-1.369.632L18 12l-.631-1.368L16 10l1.369-.631L18 8ZM11.5 5l.79 1.71L14 7.5l-1.71.79L11.5 10l-.79-1.71L9 7.5l1.71-.79L11.5 5Z"
      />
      <path stroke={stroke} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m1 20 12-8" />
      <path
        stroke={stroke}
        fill="none"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M28 2h11a1 1 0 0 1 1 1v21a1 1 0 0 1-1 1H26"
      />
      <path
        stroke="#AFAFAF"
        fill="none"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M35 8h-8a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Z"
      />
      <path stroke={stroke} fill="none" strokeLinejoin="round" strokeWidth={1.5} d="M22 7v21" />
      <path
        stroke={stroke}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M22 7.5A5.5 5.5 0 0 0 16.5 2M22 8a6 6 0 0 1 6-6M22 29a4 4 0 0 0-4-4M22 29a4 4 0 0 1 4-4"
      />
    </svg>
  )
}

export { BookGenerate }
