import { IconProps } from "@app/types"

const Youtube = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 26 26" {...props}>
      <g fill={fill} fillRule="evenodd">
        <path
          d="M13 .5C6.096.5.5 6.096.5 13S6.096 25.5 13 25.5 25.5 19.904 25.5 13 19.904.5 13 .5zM13 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2z"
          fillRule="nonzero"
        />
        <path d="M18.4 9.67a1.665 1.665 0 00-1.2-.54C15.52 9 13 9 13 9s-2.52 0-4.2.13c-.45.005-.879.198-1.2.54a2.868 2.868 0 00-.48 1.267A20.636 20.636 0 007 13.014v.964c.005.691.046 1.382.12 2.068.057.463.223.903.48 1.277.368.345.835.539 1.32.547.96.098 4.08.13 4.08.13s2.52 0 4.2-.13a1.673 1.673 0 001.2-.54c.257-.37.422-.807.48-1.266.075-.693.116-1.389.12-2.086v-.964a20.696 20.696 0 00-.12-2.069 2.866 2.866 0 00-.48-1.275zm-6.637 5.608V11.69l3.247 1.8-3.248 1.79z" />
      </g>
    </svg>
  )
}

export { Youtube }
