import { IconProps } from "@app/types"

const Error = ({ width, height, fill = "none", stroke = "#AFAFAF", className, ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 42 42"
      {...props}
    >
      <g transform="translate(1 1)" strokeWidth={1.5} fill={fill} fillRule="evenodd">
        <circle cx={20} cy={20} r={20} stroke="#DEDEDE" />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20 20l6-6-6 6-6-6 6 6zm0 0l6 6-6-6-6 6 6-6z"
        />
      </g>
    </svg>
  )
}

export { Error }
