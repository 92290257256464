import PropTypes from "prop-types"

function User({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 16 18" {...rest}>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        transform="translate(1 1)"
      >
        <path d="M14 16v-1c0-1.84-2.067-4-4-4H4c-1.933 0-4 2.16-4 4v1" />
        <circle cx={7} cy={4} r={4} />
      </g>
    </svg>
  )
}

User.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { User }
