import * as React from "react"

const ChevronDown = ({ fill, ...props }) => (
  <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke={fill} strokeWidth={1.5} fill="none" fillRule="evenodd">
      <path strokeLinecap="round" strokeLinejoin="round" d="m12 16 3 3 6-6" />
    </g>
  </svg>
)

export default ChevronDown
