import { IconProps } from "@app/types"

const Hidden = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 16" {...props}>
      <g stroke={stroke} fill="none" fillRule="evenodd">
        <path
          d="M6.032 2.576C2.899 4.229 1.5 7.28 1.5 8c0 1 2.7 6.5 9 6.5 2.262 0 4.06-.71 5.436-1.664m2.24-2.156C19.079 9.495 19.5 8.396 19.5 8c0-1-2.7-6.5-9-6.5-.55 0-1.074.042-1.57.12M4.5 1l13 13"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.718 6.72c.626.546.94.892 1.226 1.235.212.253.41.504.561.72-.153.574-.54.825-1.005.825a1.49 1.49 0 0 1-1.06-.44 1.495 1.495 0 0 1-.174-1.912c.119-.173.273-.319.452-.429z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export { Hidden }
