import { IconProps } from "@app/types"

const VkShare = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 1.5C7.992 1.5 1.5 7.992 1.5 16S7.992 30.5 16 30.5 30.5 24.008 30.5 16 24.008 1.5 16 1.5z" />
        <path d="M19.788 17.014c-.21-.245-.15-.354 0-.573.002-.002 1.737-2.215 1.916-2.966h.001c.09-.274 0-.475-.43-.475h-1.42c-.363 0-.53.172-.619.366 0 0-.723 1.598-1.747 2.635-.33.3-.483.395-.664.395-.089 0-.227-.096-.227-.369v-2.553c0-.328-.101-.474-.4-.474h-2.236c-.227 0-.361.153-.361.296 0 .31.511.382.565 1.257v1.898c0 .416-.082.493-.264.493-.483 0-1.656-1.605-2.35-3.443-.141-.356-.278-.5-.643-.5H9.488c-.406 0-.488.172-.488.365 0 .341.483 2.037 2.247 4.276 1.175 1.53 2.83 2.358 4.337 2.358.905 0 1.015-.184 1.015-.5 0-1.462-.081-1.6.372-1.6.21 0 .572.097 1.417.834.966.874 1.124 1.266 1.665 1.266h1.421c.406 0 .611-.184.493-.547-.27-.764-2.097-2.334-2.18-2.439z" />
      </g>
    </svg>
  )
}

export { VkShare }
