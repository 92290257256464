import { IconProps } from "@app/types"

const Email = ({ width, height, stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
      <g stroke={stroke} strokeWidth={1.5} fill="none" fillRule="evenodd">
        <circle cx={16} cy={16} r={15.25} />
        <rect width={10.5} height={8.5} x={10.75} y={11.75} rx={1} />
        <path strokeLinecap="round" strokeLinejoin="round" d="M11 12l5 5 5-5" />
      </g>
    </svg>
  )
}

export { Email }
