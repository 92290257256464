import { IconProps } from "@app/types"

const Check = ({ width, height, fill = "none", stroke = "#000", className, ...rest }: IconProps) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 14 10" {...rest}>
      <path
        d="M5.5 8.5l-4-3m4 3l7-7"
        fill={fill}
        stroke={stroke}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </svg>
  )
}

export { Check }
