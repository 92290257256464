import { IconProps } from "@app/types"

const NotFoundPhotoIcon = ({
  width = 38,
  height = 32,
  stroke = "#AFAFAF",
  fill = "#AFAFAF",
  className,
  ...props
}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        fillRule="nonzero"
        d="m21.122 17.919-.022.03-.07.082-.074.065-.03.022a.77.77 0 0 1-.051.032l-.093.045-.126.039-.108.015h-.101l-.097-.014-.069-.018-.066-.023-.08-.038-.05-.031-.076-.058-.073-.072-.07-.095-.042-.075-.015-.033-.035-.105-.176-.678c-1.31-4.883-3.117-7.759-6.098-7.759-3.03 0-5.658 4.919-7.578 14.902l-.172.917v.181H23a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.75.75H5a.75.75 0 0 1-.75-.75V1A.75.75 0 0 1 5 .25h28a.75.75 0 0 1 .75.75v16a.75.75 0 1 1-1.5 0V1.75H5.75v16.468C7.684 11.265 10.214 7.75 13.5 7.75c3.751 0 5.83 2.97 7.297 7.975 1.09-1.315 2.372-1.975 3.703-1.975 1.282 0 3.12 1.314 3.92 2.915a.75.75 0 1 1-1.34.67c-.562-1.122-1.91-2.085-2.58-2.085-1.104 0-2.233.736-3.206 2.374l-.172.295ZM26.5 6a1.5 1.5 0 0 1 .144 2.993L26.5 9a1.5 1.5 0 0 1 0-3Z"
      />
      <path
        fill={fill}
        fillRule="nonzero"
        d="M30 16a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm0 1.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M30 21.5v5.25M32.5 24h-5.25"
      />
      <path d="M0 0h38v32H0z" />
    </g>
  </svg>
)
export { NotFoundPhotoIcon }
