import { IconProps } from "@app/types"

const WhatsApp = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
      <g fillRule="nonzero" fill="none">
        <circle cx={16} cy={16} r={15.25} stroke={stroke} strokeWidth={1.5} />
        <path
          d="M21.327 10.277A7.717 7.717 0 0015.832 8c-4.28 0-7.765 3.483-7.767 7.764a7.75 7.75 0 001.037 3.882L8 23.67l4.118-1.08a7.753 7.753 0 003.712.946h.003c4.281 0 7.766-3.484 7.768-7.765a7.725 7.725 0 00-2.274-5.494zm-5.495 11.947h-.002a6.435 6.435 0 01-3.286-.9l-.236-.14-2.443.642.652-2.382-.154-.245a6.441 6.441 0 01-.987-3.434c.001-3.559 2.898-6.453 6.458-6.453a6.41 6.41 0 014.565 1.892 6.413 6.413 0 011.889 4.566 6.463 6.463 0 01-6.456 6.454zm3.542-4.833c-.195-.097-1.149-.567-1.326-.632-.178-.065-.308-.096-.437.097-.13.195-.501.631-.614.76-.113.13-.227.146-.42.05-.195-.097-.82-.303-1.562-.964-.577-.514-.966-1.15-1.08-1.344-.112-.194-.012-.299.086-.396.087-.087.194-.227.291-.34.097-.113.13-.194.194-.324.066-.129.033-.243-.016-.34-.049-.096-.437-1.052-.598-1.44-.158-.378-.318-.327-.437-.333a8.768 8.768 0 00-.372-.006c-.13 0-.34.049-.517.243-.178.194-.68.664-.68 1.618 0 .955.696 1.878.793 2.008.097.129 1.368 2.089 3.315 2.93.463.199.825.318 1.107.408.464.148.888.127 1.222.077.373-.056 1.149-.47 1.31-.923.162-.453.162-.841.113-.922-.049-.082-.178-.13-.372-.227z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

export { WhatsApp }
