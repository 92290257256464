import { IconProps } from "@app/types"

const Info = ({ width, height, fill = "#AFAFAF", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 17 17" {...props}>
      <g stroke={fill} fill="none" fillRule="evenodd">
        <circle cx="8.5" cy="8.5" r="8" />
        <rect strokeWidth={0.5} fill={fill} x={8} y={5} width={1} height={4} rx={0.5} />
        <rect strokeWidth={0.5} fill={fill} x={8} y={11} width={1} height={1} rx={0.5} />
      </g>
    </svg>
  )
}

export { Info }
