import PropTypes from "prop-types"

function BurgerMenu({ width, height, fill = "#000", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" {...rest}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-275.000000, -20.000000)">
          <g transform="translate(275.000000, 20.000000)">
            <path
              fill={fill}
              d="M1,4.25 L19,4.25 C19.4142136,4.25 19.75,3.91421356 19.75,3.5 C19.75,3.08578644 19.4142136,2.75 19,2.75 L1,2.75 C0.585786438,2.75 0.25,3.08578644 0.25,3.5 C0.25,3.91421356 0.585786438,4.25 1,4.25 Z M1,10.25 L19,10.25 C19.4142136,10.25 19.75,9.91421356 19.75,9.5 C19.75,9.08578644 19.4142136,8.75 19,8.75 L1,8.75 C0.585786438,8.75 0.25,9.08578644 0.25,9.5 C0.25,9.91421356 0.585786438,10.25 1,10.25 Z M1,16.25 L19,16.25 C19.4142136,16.25 19.75,15.9142136 19.75,15.5 C19.75,15.0857864 19.4142136,14.75 19,14.75 L1,14.75 C0.585786438,14.75 0.25,15.0857864 0.25,15.5 C0.25,15.9142136 0.585786438,16.25 1,16.25 Z"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

BurgerMenu.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { BurgerMenu }
