import { IconProps } from "@app/types"

const PlusBig = ({ width, height, stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path d="M1440 0v2142H0V0h1440z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="translate(-1313 -900)">
        <path
          d="M1314 911h20m-10-10v20"
          stroke={stroke}
          strokeWidth={1.5}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export { PlusBig }
