import PropTypes from "prop-types"

function EyeOff({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 15 12" {...rest}>
      <g fill={fill} fillRule="evenodd" stroke={stroke} strokeWidth={1.5}>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.208 9.557a8.45 8.45 0 01-.42.321c-.45.319-.942.594-1.477.792A5.178 5.178 0 017.5 11C3.585 11 1.238 6.479 1 6c0 0 .585-1.238 1.683-2.483a9.63 9.63 0 011.12-1.084 6.75 6.75 0 01.285-.22m2.34-1.101c.337-.071.689-.11 1.055-.11 3.915 0 6.262 4.52 6.5 5 0 0-.45.95-1.3 2.023M3.167 1l9.389 10"
        />
        <path
          fill={stroke}
          d="M8.009 7.11c-.055-.059-.12-.126-.195-.203L7.4 6.485l-.184-.19a9.228 9.228 0 01-.702-.805l-.017-.02a1.387 1.387 0 00-.104.53c0 .702.508 1.25 1.107 1.25.18 0 .354-.049.509-.14z"
        />
      </g>
    </svg>
  )
}

EyeOff.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { EyeOff }
