import { IconProps } from "@app/types"

const TrashInCircle = ({ width = 32, height = 32, fill = "#000", stroke = "#fff", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none" {...props}>
      <circle opacity="0.16" cx="16" cy="16" r="16" fill={fill} />
      <path d="M8 12H9H24" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M22.75 12C22.75 11.5858 22.4142 11.25 22 11.25C21.5858 11.25 21.25 11.5858 21.25 12H22.75ZM10.75 12C10.75 11.5858 10.4142 11.25 10 11.25C9.58579 11.25 9.25 11.5858 9.25 12H10.75ZM11.25 12C11.25 12.4142 11.5858 12.75 12 12.75C12.4142 12.75 12.75 12.4142 12.75 12H11.25ZM19.25 12C19.25 12.4142 19.5858 12.75 20 12.75C20.4142 12.75 20.75 12.4142 20.75 12H19.25ZM21.25 12V21.625H22.75V12H21.25ZM21.25 21.625C21.25 21.6866 21.2143 21.8245 20.9893 21.9792C20.7676 22.1317 20.4201 22.25 20 22.25V23.75C20.6844 23.75 21.3369 23.5605 21.8391 23.2153C22.338 22.8723 22.75 22.3228 22.75 21.625H21.25ZM20 22.25H12V23.75H20V22.25ZM12 22.25C11.5799 22.25 11.2324 22.1317 11.0107 21.9792C10.7857 21.8245 10.75 21.6866 10.75 21.625H9.25C9.25 22.3228 9.66205 22.8723 10.1609 23.2153C10.6631 23.5605 11.3156 23.75 12 23.75V22.25ZM10.75 21.625V12H9.25V21.625H10.75ZM12.75 12V10H11.25V12H12.75ZM12.75 10C12.75 9.30964 13.3096 8.75 14 8.75V7.25C12.4812 7.25 11.25 8.48122 11.25 10H12.75ZM14 8.75H18V7.25H14V8.75ZM18 8.75C18.6904 8.75 19.25 9.30964 19.25 10H20.75C20.75 8.48122 19.5188 7.25 18 7.25V8.75ZM19.25 10V12H20.75V10H19.25Z"
        fill={stroke}
      />
      <path d="M14 15.5V19.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18 15.75V19.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export { TrashInCircle }
