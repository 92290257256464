import { IconProps } from "@app/types"

const Share = ({ width, height, fill = "none", stroke = "#000", strokeWidth = 0, className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 21 17" {...props}>
      <path
        d="M11.268 2c0-1.318 1.578-1.995 2.534-1.087l6.231 5.921a1.5 1.5 0 01.006 2.17l-6.232 5.981c-.953.915-2.539.24-2.539-1.082v-2.226a8.337 8.337 0 00-3.125.435c-1.893.634-3.545 1.89-4.935 3.777-.856 1.163-2.699.564-2.708-.88-.005-.804.075-1.607.238-2.396.537-2.591 1.91-4.783 4.052-6.477-.031.026-.031.026-.024.019.375-.332 1.038-.755 1.972-1.139 1.278-.526 2.79-.857 4.53-.919V2zm1.5 11.903L19 7.922 12.768 2v2.857c0 .403-.342.73-.764.73-4.249-.03-6.19 1.643-6.243 1.69a.693.693 0 01-.047.041c-1.863 1.472-3.042 3.357-3.507 5.6A9.946 9.946 0 002 15c1.567-2.127 3.469-3.575 5.667-4.31 2.061-.69 3.805-.53 4.459-.43a.74.74 0 01.642.721v2.922z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fillRule="nonzero"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { Share }
