import PropTypes from "prop-types"

function TabletDevice({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 18 15"
      {...rest}
    >
      <path
        d="M2.313 1h13.374C16.412 1 17 1.588 17 2.313v7.375c0 .724-.588 1.312-1.313 1.312H2.313A1.313 1.313 0 011 9.687V2.314C1 1.588 1.588 1 2.313 1zM2.5 14h13"
        stroke={stroke}
        strokeWidth={1.5}
        fill={fill}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

TabletDevice.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { TabletDevice }
