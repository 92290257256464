import { IconProps } from "@app/types"

interface BookIconProps extends IconProps {
  style?: any
}

const BookYourself = (props: BookIconProps) => {
  const { fill, stroke, width, height } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 40 32" {...props}>
      <g strokeLinejoin="round" strokeWidth={1.5}>
        <path stroke={stroke} fill="none" d="M14.5 2H3a1 1 0 0 0-1 1v15" />
        <path stroke={stroke} fill="none" strokeLinecap="round" d="M16 25H3a1 1 0 0 1-1-1v-6.5" />
        <path stroke={stroke} d="M26 2h11a1 1 0 0 1 1 1v21a1 1 0 0 1-1 1H24" />
        <path
          stroke="#AFAFAF"
          fill="none"
          strokeDasharray="3 4"
          strokeLinecap="round"
          d="M33 8h-8a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1ZM15 8H7a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Z"
        />
        <path stroke={stroke} fill="none" d="M20 7v21" />
        <path
          stroke={stroke}
          fill="none"
          strokeLinecap="round"
          d="M20 7.5A5.5 5.5 0 0 0 14.5 2M20 8a6 6 0 0 1 6-6M20 29a4 4 0 0 0-4-4M20 29a4 4 0 0 1 4-4"
        />
      </g>
    </svg>
  )
}

export { BookYourself }
