import { IconProps } from "@app/types"

const Cross = ({
  width = 20,
  height = 20,
  fill = "none",
  stroke = "var(--color-black)",
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      className={className}
      {...props}
    >
      <path
        d="M8 8l7-7-7 7-7-7 7 7zm0 0l7 7-7-7-7 7 7-7z"
        stroke={stroke}
        strokeWidth={1.5}
        fill={fill}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { Cross }
