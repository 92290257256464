import PropTypes from "prop-types"

function Picture({ width, height, fill = "none", stroke = "#AFAFAF", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 12 9" {...rest}>
      <g fill={fill} fillRule="evenodd">
        <path stroke={stroke} strokeLinejoin="round" d="M.5.5h11v8H.5z" />
        <path stroke={stroke} strokeLinecap="round" strokeLinejoin="round" d="M.5 6.5L4 4l4.5 4.5" />
        <circle cx={9} cy={3} r={1} fill="#AFAFAF" />
      </g>
    </svg>
  )
}
Picture.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  className: PropTypes.string,
}

export { Picture }
