import { IconProps } from "@app/types"

const ImageLowQuality = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx={24} cy={24} r={24} />
        <g transform="translate(14.5 15.5)">
          <path stroke={stroke} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M7 14H0V0h17v4" />
          <circle fill={fill} cx={13} cy={4} r={1.5} />
          <path
            d="M0 14c.333-6 1.833-9 4.5-9C6.167 5 7.333 6 8 8"
            stroke={stroke}
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle fill="#F64853" cx={16} cy={13} r={6.5} />
          <rect stroke="#FFF" strokeWidth={0.5} fill="#FFF" x={15.5} y={15.5} width={1} height={1} rx={0.5} />
          <path stroke="#FFF" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" d="M16 10v3.5" />
        </g>
      </g>
    </svg>
  )
}

export { ImageLowQuality }
