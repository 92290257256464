import * as React from "react"

const Printer = ({ width = 20, height = 21, stroke = "#000", className = "" }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" className={className}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v21H0z" />
      <path
        d="M6 17H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-3"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 14h6a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm8-6V5.667C15 4.747 14.204 4 13.222 4H6.778C5.796 4 5 4.746 5 5.667V8"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export { Printer }
