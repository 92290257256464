import PropTypes from "prop-types"

function Plus({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} viewBox="0 0 38 38" className={className} {...rest}>
      <path
        fill={fill}
        stroke={stroke}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19 37V1M1 19h36"
      />
    </svg>
  )
}

Plus.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { Plus }
