import { IconProps } from "@app/types"

const Download = ({ width, height, fill = "none", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 18 18" {...props}>
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth={1.5}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17 12v3.333c0 .92-.796 1.667-1.778 1.667H2.778C1.796 17 1 16.254 1 15.333V12m3-5l5 5 5-5m-5 5V1"
      />
    </svg>
  )
}

export { Download }
