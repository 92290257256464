import PropTypes from "prop-types"

function Edit({ width, height, fill = "none", stroke = "#000", className = "", ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 18 18" {...rest}>
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M13 1.8A2.263 2.263 0 1116.2 5L5.4 15.8 1 17l1.2-4.4L13 1.8z"
      />
    </svg>
  )
}

Edit.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { Edit }
