import { IconProps } from "@app/types"

const EmailOrder = ({ width, height, stroke, className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" fill="none" {...props}>
      <rect x="0.75" y="4.75" width="23.5" height="16.5" rx="1.03571" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M0.892883 4.89307L11.2623 14.8637C11.9536 15.5284 13.0464 15.5284 13.7377 14.8637L24.1072 4.89307"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { EmailOrder }
