import { IconProps } from "@app/types"

const Like = ({ width, height, fill = "none", stroke = "#FFF", className, ...props }: IconProps) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 16" {...props}>
      <g fill={fill} fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke={stroke} strokeWidth={1.5}>
          <path
            transform="translate(-132 -261) translate(29 152) translate(30 109) translate(74 1)"
            d="M8 14c-.228 0-.447-.081-.619-.229-.646-.555-1.27-1.078-1.819-1.539l-.003-.002c-1.612-1.351-3.004-2.518-3.972-3.667C.504 7.278 0 6.06 0 4.729 0 3.436.45 2.243 1.27 1.37 2.097.487 3.233 0 4.47 0c.923 0 1.77.287 2.514.853.375.286.716.636 1.016 1.044.3-.408.64-.758 1.016-1.044C9.761.287 10.606 0 11.53 0c1.236 0 2.373.487 3.2 1.37.82.873 1.27 2.066 1.27 3.36 0 1.33-.504 2.548-1.587 3.833-.968 1.15-2.36 2.316-3.972 3.667-.55.461-1.175.984-1.823 1.542-.17.147-.39.228-.618.228"
          />
        </g>
      </g>
    </svg>
  )
}

export { Like }
