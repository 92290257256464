import { IconProps } from "@app/types"

const Delivery = ({ width, height, stroke, fill, className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.5H20C20.2761 12.5 20.5 12.7239 20.5 13V25.5H11.3779C11.4565 25.6714 11.5 25.8603 11.5 26.0588C11.5 26.4204 11.3556 26.7502 11.1181 27H20.5H22V25.5V13C22 11.8954 21.1046 11 20 11H7C5.89543 11 5 11.8954 5 13V25C5 26.1046 5.89543 27 7 27H8.88195C8.64443 26.7502 8.5 26.4204 8.5 26.0588C8.5 25.8603 8.54353 25.6714 8.6221 25.5H7C6.72386 25.5 6.5 25.2761 6.5 25V13C6.5 12.7239 6.72386 12.5 7 12.5Z"
        fill={fill}
      />
      <circle cx="10" cy="26" r="2.25" stroke={stroke} strokeWidth="1.5" />
      <circle cx="23.5" cy="26" r="2.25" stroke={stroke} strokeWidth="1.5" />
      <path
        d="M22 15H24.5218C25.139 15 25.7216 15.2849 26.1005 15.7721L28.5787 18.9583C28.8518 19.3094 29 19.7415 29 20.1862V23.5585C29 24.4193 28.4491 25.1836 27.6325 25.4558L26 26"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6321 1.32156C4.4415 1.22651 4.21947 1.21697 4.02143 1.29534C3.82338 1.3737 3.668 1.53257 3.59404 1.73231L0.561489 9.92263C0.458714 10.2002 0.528804 10.5123 0.740409 10.7192C0.952014 10.9262 1.26555 10.9893 1.54078 10.8804L3.28894 10.1887L1.49334 15.4857C1.39953 15.7625 1.475 16.0685 1.68672 16.2699C1.89845 16.4713 2.20787 16.5314 2.47959 16.4239L5.73583 15.1354L5.64266 13.5591L3.43401 14.4331L5.22961 9.13606C5.32342 8.85931 5.24795 8.55327 5.03622 8.35187C4.8245 8.15046 4.51507 8.09037 4.24335 8.19789L2.55178 8.86723L4.71054 3.03686L10.2742 5.81151L8.58266 6.48086C8.31094 6.58837 8.12641 6.84392 8.10982 7.13567C8.09323 7.42741 8.2476 7.70223 8.50538 7.83986L13.4393 10.474L9.81909 11.9065L14.5442 11.6272L14.5455 11.6495L15.4975 11.2728C15.7692 11.1653 15.9538 10.9097 15.9704 10.618C15.9869 10.3262 15.8326 10.0514 15.5748 9.91379L10.6409 7.27959L12.389 6.58786C12.6643 6.47896 12.8497 6.21837 12.8624 5.92265C12.8751 5.62693 12.7127 5.3514 12.4478 5.21931L4.6321 1.32156Z"
        fill={fill}
      />
    </svg>
  )
}

export { Delivery }
