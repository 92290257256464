import PropTypes from "prop-types"

function ChevronTop({ width, height, fill, stroke, strokeWidth = "0", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 8 5" {...rest}>
      <path
        d="M1.53 4.53A.75.75 0 01.47 3.47l3-3a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06L4 2.06 1.53 4.53z"
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        fillRule="nonzero"
      />
    </svg>
  )
}

ChevronTop.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { ChevronTop }
