import * as React from "react"

const ZoomIn = ({ width = 18, height = 18, fill = '#fff', ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...rest}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.5 8.5a7 7 0 1 1-14 0 7 7 0 0 1 14 0Zm-1.543 6.517a8.5 8.5 0 1 1 1.06-1.06l2.695 2.695a.75.75 0 0 1-1.06 1.06l-2.695-2.695ZM4.25 8.5A.75.75 0 0 1 5 7.75h2.75V5a.75.75 0 0 1 1.5 0v2.75H12a.75.75 0 0 1 0 1.5H9.25V12a.75.75 0 0 1-1.5 0V9.25H5a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
  </svg>
)

export { ZoomIn }
