import PropTypes from "prop-types"

function ChevronRight({ width, height, fill = "none", stroke, strokeWidth, className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 8 14" {...rest}>
      <path
        d="M5.94 7L.47 12.47a.75.75 0 001.06 1.06l6-6a.75.75 0 000-1.06l-6-6A.75.75 0 00.47 1.53L5.94 7z"
        fill={fill}
        strokeWidth={strokeWidth}
        fillRule="nonzero"
      />
    </svg>
  )
}

ChevronRight.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { ChevronRight }
