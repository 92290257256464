import PropTypes from "prop-types"

function Screen({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 24 20" {...rest}>
      <g
        fill={fill}
        stroke={stroke}
        strokeWidth={1.5}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(1 1)"
      >
        <rect width={22} height={14} rx={1.75} />
        <path d="M2 17.5h18" />
      </g>
    </svg>
  )
}

Screen.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { Screen }
