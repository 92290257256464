import { IconProps } from "@app/types"

interface BookIconProps extends IconProps {
  style?: any
}

const Book = (props: BookIconProps) => {
  const { fill, stroke } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 31 25">
      <g fill="none" fillRule="evenodd">
        <path
          d="M15.5 20.5v-15c0-2.157 2.345-4 4.5-4h9.5v19h-9.427M10 20.5H1.5v-19H11c2.155 0 4.5 1.843 4.5 4V23"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M1.854 20.5H11.5c2.155 0 4 2 4 3.5"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M29.5 20.5h-10c-2.028 0-4 1.5-4 3.5" stroke={stroke} strokeWidth="1.5" strokeLinejoin="round" />
        <path stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M5 6.5h7M5 15h7" />
        <path
          stroke="none"
          fill={fill}
          d="m21.983 12.068-.74 1.604-.738-1.604-1.603-.74 1.603-.74.739-1.604.74 1.604 1.601.74zM23.03 6.085l-.615 1.337-.616-1.337-1.336-.616 1.336-.617.616-1.336.616 1.336 1.335.617zM26.2 8.696l-.493 1.07-.493-1.07-1.068-.493 1.068-.493.493-1.07.493 1.07 1.068.493z"
        />
        <path stroke={stroke} strokeWidth="1.5" d="M5 6.5V15M12 6.5V15" />
      </g>
    </svg>
  )
}

export { Book }
