import * as React from "react"

const YandexDiscLight = ({ width, height, fill = "#ffffff", secondFill = "#0077FE", ...props }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M13.73 5.683c-2.23.011-5.293 1.533-6.899 2.984-1.75 1.581-.884 2.473 1.07 2.218 2.079-.272 4.68-1.511 6.214-2.91 1.149-1.046 1.55-2.312-.386-2.292zM.102 11.022c-.094-.453.145-1.101.352-1.495C1.648 7.255 4.863 5.03 7.067 3.894c2.678-1.382 5.35-2.37 8.379-2.654.755-.07 1.649-.06 2.396.1.242.05.494.172.715.27.115.052.218.147.332.2.805.608.658 1.58.33 2.408-.653 1.639-2.052 3.161-3.648 4.414a21.466 21.466 0 0 1-5.982 3.295c-1.428.503-3.72.93-5.759.912-1.184-.01-3.455-.489-3.726-1.817H.103z"
        fill={secondFill}
      />
      <path
        d="M18.976 1.875c-.268-.192-.799-.402-1.12-.47-.751-.159-1.506-.167-2.261-.094-3.029.287-5.821 1.33-8.499 2.714-1.753.906-3.396 1.98-4.846 3.331-.698.65-1.34 1.35-1.785 2.204-.312.594-.343.881-.356 1.472a2.89 2.89 0 0 1-.047-.188c-.101-.44-.062-.882.052-1.321.271-1.037.85-1.905 1.508-2.73 1.153-1.32 1.261-1.62 2.053-3.189C5.3.577 8.68-.651 11.945.334c2.026.737 3.93.042 6.203.957.282.113.637.334.828.584z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export { YandexDiscLight }
