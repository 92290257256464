import PropTypes from "prop-types"

function Heart({ width, height, fill, stroke, className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 9 8" {...rest}>
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M4.5 7a.417.417 0 01-.27-.098c-.283-.238-.556-.462-.797-.66h0c-.706-.58-1.315-1.08-1.739-1.572C1.221 4.12 1 3.597 1 3.027c0-.554.197-1.066.555-1.44A1.92 1.92 0 012.955 1a1.8 1.8 0 011.1.366c.165.122.314.272.445.447.131-.175.28-.325.445-.447A1.8 1.8 0 016.045 1c.54 0 1.037.209 1.4.587.358.374.555.886.555 1.44 0 .57-.22 1.092-.694 1.643-.424.492-1.033.992-1.738 1.571-.241.198-.514.422-.797.661A.421.421 0 014.5 7"
      />
    </svg>
  )
}

Heart.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { Heart }
