import { IconProps } from "@app/types"

const Upload = ({ width = 18, height = 18, fill = "none", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" fill="none">
      <path
        d="M17 12V15.3333C17 16.2538 16.2041 17 15.2222 17H2.77778C1.79594 17 1 16.2538 1 15.3333V12"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 6L9 1L4 6" stroke={stroke} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 1L9 12" stroke={stroke} strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export { Upload }
