import { IconProps } from "@app/types"

const ArrowLeft = ({ width, height, fill, stroke, strokeWidth = 0, className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 15" className={className} {...props}>
      <g fill={fill} stroke={stroke} strokeWidth={strokeWidth} fillRule="nonzero">
        <path d="M2.56 7.5l5.47 5.47a.75.75 0 01-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 1.06L2.56 7.5z" />
        <path d="M1.5 6.75h30a.75.75 0 110 1.5h-30a.75.75 0 010-1.5z" />
      </g>
    </svg>
  )
}

export { ArrowLeft }
