import PropTypes from "prop-types"

function ChevronLeft({ width, height, fill = "none", stroke, strokeWidth, className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 8 14" {...rest}>
      <path
        d="M2.06 7l5.47 5.47a.75.75 0 01-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 1.06L2.06 7z"
        fill={fill}
        strokeWidth={strokeWidth}
        fillRule="nonzero"
      />
    </svg>
  )
}

ChevronLeft.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { ChevronLeft }
