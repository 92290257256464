import { IconProps } from "@app/types"

const Telegram = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M13 .5C6.096.5.5 6.096.5 13S6.096 25.5 13 25.5 25.5 19.904 25.5 13 19.904.5 13 .5zM13 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2z" />
        <path d="M10.1 15.25l-.214 3.07c.308 0 .44-.133.6-.295l1.443-1.4 2.989 2.223c.548.31.934.147 1.082-.512l1.962-9.334V9c.175-.823-.292-1.145-.826-.943L5.603 12.541c-.787.31-.775.756-.133.958l2.948.931 6.848-4.351c.323-.217.616-.097.375.12l-5.54 5.05z" />
      </g>
    </svg>
  )
}

export { Telegram }
