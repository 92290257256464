import { IconProps } from "@app/types"

const Facebook = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M13 .5C6.096.5.5 6.096.5 13S6.096 25.5 13 25.5 25.5 19.904 25.5 13 19.904.5 13 .5zM13 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2z" />
        <path d="M13.694 14.232h1.894l.33-2.305h-2.306v-1.51c0-.636.33-1.271 1.317-1.271H16V7.159S15.094 7 14.188 7c-1.894 0-3.13 1.113-3.13 3.1v1.748H9v2.384h2.059v4.689c.412.079.906.079 1.317.079.412 0 .906 0 1.318-.08v-4.688z" />
      </g>
    </svg>
  )
}

export { Facebook }
