import { IconProps } from "@app/types"

const Pinterest = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
      <g fillRule="nonzero" fill="none">
        <circle cx={16} cy={16} r={15.25} stroke={stroke} strokeWidth={1.5} />
        <path
          fill={fill}
          d="M16.167 10C12.793 10 11 12.284 11 14.773c0 1.155.61 2.595 1.589 3.051.279.133.242-.029.482-.998a.239.239 0 00-.052-.226c-1.398-1.708-.273-5.22 2.949-5.22 4.663 0 3.792 6.816.811 6.816-.768 0-1.34-.637-1.16-1.425.22-.939.65-1.948.65-2.624 0-1.706-2.405-1.453-2.405.807 0 .698.234 1.17.234 1.17l-.918 3.918c-.243 1.044.033 2.735.057 2.88.015.08.1.106.148.04.076-.105 1.012-1.515 1.274-2.534l.486-1.877c.258.492 1.002.904 1.794.904 2.357 0 4.061-2.188 4.061-4.904C20.992 11.948 18.882 10 16.167 10z"
        />
      </g>
    </svg>
  )
}

export { Pinterest }
