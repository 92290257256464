import { IconProps } from "@app/types"

const FacebookShare = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 1.5C7.992 1.5 1.5 7.992 1.5 16S7.992 30.5 16 30.5 30.5 24.008 30.5 16 24.008 1.5 16 1.5z" />
        <path d="M16.694 17.232h1.894l.33-2.305h-2.306v-1.51c0-.636.33-1.271 1.317-1.271H19v-1.987S18.094 10 17.188 10c-1.894 0-3.13 1.113-3.13 3.1v1.748H12v2.384h2.059v4.689c.412.079.906.079 1.317.079.412 0 .906 0 1.318-.08v-4.688z" />
      </g>
    </svg>
  )
}

export { FacebookShare }
