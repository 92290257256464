import { IconProps } from "@app/types"

const Vimeo = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 26 26" className={className} {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M13 .5C6.096.5.5 6.096.5 13S6.096 25.5 13 25.5 25.5 19.904 25.5 13 19.904.5 13 .5zM13 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2z" />
        <path d="M16.394 8.003c-1.525-.058-2.555.85-3.099 2.711.28-.13.551-.188.815-.188.558 0 .805.332.74.991-.034.4-.28.98-.74 1.745-.462.764-.808 1.147-1.038 1.147-.697 0-1.117-3.468-1.259-4.416-.197-1.316-.724-1.932-1.58-1.846-.785.078-2.156 1.547-3.233 2.544l.517.7c.493-.362.78-.544.863-.544.857 0 1.272 3.032 1.938 5.286.447 1.245.983 1.867 1.629 1.867 1.037 0 2.301-1.02 3.8-3.064 1.445-1.955 2.194-3.497 2.242-4.621l.006-.005c.062-1.507-.468-2.278-1.6-2.307z" />
      </g>
    </svg>
  )
}

export { Vimeo }
