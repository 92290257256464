import PropTypes from "prop-types"

function DeleteUser({ width, height, fill = "#DDD", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 21 21" {...rest}>
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M14 16v-1c0-1.84-2.067-4-4-4H4c-1.933 0-4 2.16-4 4v1"
        />
        <circle cx={7} cy={4} r={4} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} />
        <rect width={12} height={12} x={8} y={8} fill={fill} rx={6} />
        <path
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M14 14l1.5-1.5L14 14l-1.5-1.5L14 14zm0 0l-1.5 1.5L14 14l1.5 1.5L14 14z"
        />
      </g>
    </svg>
  )
}

DeleteUser.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { DeleteUser }
