import { IconProps } from "@app/types"

const SignOut = ({
  width,
  height,
  fill = "#000",
  stroke = "#000",
  fillSecond = "#ddd",
  className,
  ...props
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <path
          d="M14 16v-1c0-1.84-2.067-4-4-4H4c-1.933 0-4 2.16-4 4v1"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" cx="7" cy="4" r="4" />
        <rect fill={fillSecond} x="8" y="8" width="12" height="12" rx="6" />
        <path d="m15.5 12.5-3 3m0-3 3 3" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

export { SignOut }
