import { IconProps } from "@app/types"

const Vk = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M13 .5C6.096.5.5 6.096.5 13S6.096 25.5 13 25.5 25.5 19.904 25.5 13 19.904.5 13 .5zM13 2c6.075 0 11 4.925 11 11s-4.925 11-11 11S2 19.075 2 13 6.925 2 13 2z" />
        <path d="M16.788 14.014c-.21-.245-.15-.354 0-.573.002-.003 1.737-2.216 1.916-2.966h.001c.09-.274 0-.475-.43-.475h-1.42c-.363 0-.53.172-.619.366 0 0-.723 1.598-1.747 2.635-.33.3-.483.396-.664.396-.089 0-.227-.097-.227-.37v-2.552c0-.329-.101-.475-.4-.475h-2.236c-.227 0-.361.153-.361.296 0 .31.511.382.565 1.257v1.898c0 .416-.082.493-.264.493-.483 0-1.656-1.606-2.35-3.443-.141-.356-.278-.5-.643-.5H6.488c-.406 0-.488.172-.488.365 0 .341.483 2.037 2.247 4.277C9.422 16.17 11.077 17 12.584 17c.905 0 1.015-.184 1.015-.5 0-1.462-.081-1.6.372-1.6.21 0 .572.096 1.417.834.966.874 1.124 1.266 1.665 1.266h1.421c.406 0 .611-.184.493-.547-.27-.763-2.097-2.334-2.18-2.439z" />
      </g>
    </svg>
  )
}

export { Vk }
