import { IconProps } from "@app/types"

const HiddenMobile = ({ width, height, fill = "#000", stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 14" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.488 2.931a6.988 6.988 0 0 0-.75.516c-.64.502-1.226 1.134-1.761 1.898-.232.33-.558.882-.977 1.655 1.667 3.333 4 5 7 5a7.55 7.55 0 0 0 1.012-.067 6.571 6.571 0 0 0 2.607-.93c.186-.115.383-.265.59-.449"
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          d="M14.023 8.655c.232-.33.558-.882.977-1.655-1.667-3.333-4-5-7-5a7.55 7.55 0 0 0-1.012.067"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 9c.544 0 1.038-.218 1.399-.57C9 8 7 6 6.577 5.594A2 2 0 0 0 8 9z" fill={fill} />
        <path stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="m3 1.5 11 11" />
      </g>
    </svg>
  )
}

export { HiddenMobile }
