import PropTypes from "prop-types"

function QuantityMinus({ width, height, fill = "none", stroke = "#000", className, ...rest }) {
  return (
    <svg width={width} height={height} viewBox="0 0 11 11" className={className} {...rest}>
      <g fill={fill} fillRule="evenodd">
        <path d="M0 0h11v11H0z" />
        <path stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" d="M1.5 5.5h8" />
      </g>
    </svg>
  )
}

QuantityMinus.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  className: PropTypes.string,
}

export { QuantityMinus }
