import { IconProps } from "@app/types"

const TelegramShare = ({ width, height, fill = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" {...props}>
      <g fill={fill} fillRule="nonzero">
        <path d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm0 1.5C7.992 1.5 1.5 7.992 1.5 16S7.992 30.5 16 30.5 30.5 24.008 30.5 16 24.008 1.5 16 1.5z" />
        <path d="M13.1 18.25l-.214 3.07c.308 0 .44-.133.6-.295l1.443-1.4 2.989 2.223c.548.31.934.147 1.082-.512l1.962-9.334V12c.175-.823-.292-1.145-.826-.943L8.603 15.541c-.787.31-.775.756-.133.958l2.948.931 6.848-4.351c.323-.217.616-.097.375.12l-5.54 5.05z" />
      </g>
    </svg>
  )
}

export { TelegramShare }
