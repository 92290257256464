import { IconProps } from "@app/types"

const Copy = ({ width, height, stroke = "#000", className, ...props }: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18" {...props}>
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect width={10.4} height={10.4} x={5.6} y={5.6} rx={1.6} />
        <path d="M2.4 10.4h-.8A1.6 1.6 0 010 8.8V1.6A1.6 1.6 0 011.6 0h7.2a1.6 1.6 0 011.6 1.6v.8" />
      </g>
    </svg>
  )
}

export { Copy }
