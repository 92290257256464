import { IconProps } from "@app/types"

interface BookIconProps extends IconProps {
  style?: any
}

const BookSelfBuild = (props: BookIconProps) => {
  const { fill, stroke, width, height } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 44 32" {...props}>
      <g strokeWidth={1.5}>
        <path stroke={stroke} fill="none" strokeLinejoin="round" d="M14.5 2H3a1 1 0 0 0-1 1v15" />
        <path
          stroke={stroke}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M16 25H3a1 1 0 0 1-1-1v-6.5"
        />
        <path stroke={stroke} fill="none" strokeLinejoin="round" d="M26 2h11a1 1 0 0 1 1 1v21a1 1 0 0 1-1 1H24" />
        <path
          stroke="#AFAFAF"
          fill="none"
          strokeDasharray="3 4"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 8H7a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Z"
        />
        <path stroke={stroke} fill="none" strokeLinejoin="round" d="M20 7v21" />
        <path
          stroke={stroke}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M20 7.5A5.5 5.5 0 0 0 14.5 2M20 8a6 6 0 0 1 6-6M20 29a4 4 0 0 0-4-4M20 29a4 4 0 0 1 4-4"
        />
        <path
          fill={fill}
          stroke={stroke}
          d="M26.48 15.806a1.828 1.828 0 0 0-.003 3.163l.735.435h.002l.111.066c2.557 1.503 4.354 2.56 5.876 3 .8.232 1.553.305 2.325.184.765-.119 1.503-.42 2.285-.872a7.225 7.225 0 0 0 2.642-9.858v-.001l-1.617-2.8a1.828 1.828 0 0 0-3.35 1.384l-.48-.833a1.828 1.828 0 0 0-3.35 1.385L29.38 7.115a1.828 1.828 0 0 0-3.166 1.827l4.795 8.306-2.7-1.443-.005-.002a1.827 1.827 0 0 0-1.823.003Zm0 0-.002.001.376.65-.373-.652-.001.001Z"
        />
      </g>
    </svg>
  )
}

export { BookSelfBuild }
